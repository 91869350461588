import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { SharedBottomGradient, SharedTopGradient } from '../SharedComponents/SharedStyles';

const HeroContainer = styled(SectionLayout)`
  text-align: center;
  background: ${({ backgroundColor }) =>
    (backgroundColor && `var(${backgroundColor})`) || 'var(--skyline-3)'};
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : '')};
  background-size: ${({ socialProof }) => (socialProof ? '100% 1200px' : '100% 900px')};

  .section-layout--inner-container {
    padding-top: 200px;
    padding-bottom: 375px;

    @media (max-width: 1199px) {
      padding-bottom: 305px;
      padding-top: 170px;
    }
    @media (max-width: 767px) {
      ${({ backgroundMobileImage }) =>
        backgroundMobileImage ? `background-image: url(${backgroundMobileImage})` : ''};
      padding-bottom: 205px;
    }
  }
`;

const TopGradient = styled.div`
  ${SharedTopGradient}
`;

const BottomGradient = styled.div`
  ${SharedBottomGradient}
`;

export { HeroContainer, TopGradient, BottomGradient };
