import styled from 'styled-components';

const StyledHeading = styled.div`
  overflow: hidden;
  .MuiGrid2-root {
    margin: 0 auto;
    width: 100%;
  }

  .cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  button {
    margin-top: 0 !important;
  }

  .secondary-cta {
    width: max-content;

    p {
      color: var(--white);
    }
  }

  .social-proof-container {
    margin: 40px 0;
  }

  .social-proof {
    width: 440px;
    margin: 0 auto;
  }

  .oneCta {
    button {
      margin-right: 0 !important;
    }
  }

  .hero-contextual-cta--origin {
    margin-top: 0;
    width: 100%;
  }

  @media (max-width: 575px) {
    .social-proof-container,
    .cta-container {
      margin: 0 0 10px !important;
    }

    .social-proof {
      width: 300px;
    }

    .hero-subscribe-form-container {
      margin-top: 40px;
    }
  }
`;

export { StyledHeading };
