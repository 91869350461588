import { css } from 'styled-components';

const SharedTopGradient = css`
  position: absolute;
  top: 0%;
  width: 100%;
  height: 300px;
  background: linear-gradient(0deg, #000000 25.47%, rgba(0, 0, 0, 0) 90.24%);
  transform: rotate(180deg);
  @media (max-width: 576px) {
    display: none;
  }
`;

const SharedBottomGradient = css`
  position: absolute;
  margin-top: -140px;
  width: 100%;
  height: 140px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) -61.08%, rgba(0, 0, 0, 0) 99.7%);
  @media (max-width: 992px) {
    display: none;
  }
`;

export { SharedBottomGradient, SharedTopGradient };
