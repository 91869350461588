import styled from 'styled-components';
import { Col } from 'components/Core/Grid';
import WebsiteImage from 'components/WebsiteImage';

const FeaturedContentColumn = styled(Col)`
  position: relative;
  width: 100%;
  top: 40px !important;
  .website-image {
    width: fit-content;

    .featured-content-image {
      z-index: 2;
      position: absolute !important;
      width: 100%;
      border-radius: 15px;
      top: ${({ top }) => top[1]}px;
      animation-name: rise;
      animation-fill-mode: forwards;
      animation-play-state: ${({ hasLoaded }) => (hasLoaded ? 'running' : 'paused')};
      animation-iteration-count: 1;
      animation-duration: 1s;
      animation-delay: 2s;
      @keyframes rise {
        0% {
          top: ${({ top }) => top[1]}px;
        }
        100% {
          top: ${({ top }) => top[0]}px;
          box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.75);
        }
      }

      @media (max-width: 575px) {
        ${({ mobileTop }) =>
          mobileTop &&
          `
          top: ${mobileTop[1]}px;

          @keyframes rise {
            0% {
              top: ${mobileTop[1]}px;
            }
            100% {
              top: ${mobileTop[0]}px;
              box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.75);
            }
          }
        `};
      }
    }
  }
`;

const SideImage = styled(WebsiteImage)`
  width: 100%;
  display: block;
  position: absolute !important;
  border-radius: 15px;
  z-index: ${({ z }) => z};
  top: ${({ top }) => top[1]}px;
  animation-name: ${({ imageNum }) => `rise${imageNum}`};
  animation-fill-mode: forwards;
  animation-play-state: ${({ hasLoaded }) => (hasLoaded ? 'running' : 'paused')};
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: ${({ imageNum }) => 2 + imageNum * 0.05}s;
  @keyframes ${({ imageNum }) => `rise${imageNum}`} {
    0% {
      top: ${({ top }) => top[1]}px;
    }
    100% {
      top: ${({ top }) => top[0]}px;
      box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.75);
    }
  }
  ${({ customStyle }) => customStyle}
`;

export { FeaturedContentColumn, SideImage };
