import React from 'react';
import useIsClient from 'hooks/useIsClient';
import WebsiteImage from 'components/WebsiteImage';
import { FeaturedContentColumn, SideImage } from './styles';

const FeaturedImages = ({
  primaryImage,
  sideImages,
  featuredColumnTop = [0, 40],
  featuredColumnMobileTop,
}) => {
  const hasLoaded = useIsClient();

  return (
    <FeaturedContentColumn
      sm={24}
      md={18}
      lg={12}
      hasLoaded={hasLoaded}
      top={featuredColumnTop}
      mobileTop={featuredColumnMobileTop}
    >
      <SideImage
        loading="eager"
        className="min-md"
        image={sideImages?.[0]?.image}
        imageNum="3"
        z={0}
        top={[135, 260]}
        hasLoaded={hasLoaded}
        customStyle="right: 90%"
      />
      <SideImage
        loading="eager"
        className="min-md"
        image={sideImages?.[1]?.image}
        imageNum="2"
        z={1}
        top={[95, 210]}
        hasLoaded={hasLoaded}
        customStyle="right: 45%"
      />
      <WebsiteImage
        loading="eager"
        className="featured-content-image"
        {...primaryImage}
        style={{ marginTop: '0px' }}
      />
      <SideImage
        loading="eager"
        className="min-md"
        image={sideImages?.[2]?.image}
        imageNum="2"
        z={1}
        top={[95, 210]}
        hasLoaded={hasLoaded}
        customStyle="left: 45%"
      />
      <SideImage
        loading="eager"
        className="min-md"
        image={sideImages?.[3]?.image}
        imageNum="3"
        z={0}
        top={[135, 260]}
        hasLoaded={hasLoaded}
        customStyle="left: 90%"
      />
    </FeaturedContentColumn>
  );
};

export default FeaturedImages;
