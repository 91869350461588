import React from 'react';
import { Col } from 'components/Core/Grid';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import HeroContextualCTA from 'components/Contentful/ContentfulDynamicHero/HeroContextualCTA';
import ContentfulCallToAction from '../../../../ContentfulCallToAction';
import WebsiteImage from 'components/WebsiteImage';

import { StyledHeading } from './styles';

const Heading = ({ heading, form, primaryCta, secondaryCta, socialProof }) => {
  return (
    <StyledHeading>
      <Col xs={24} sm={20} md={18} lg={14}>
        <ContentfulRichText richText={heading} />
      </Col>
      <Col xs={24} sm={20} md={18} lg={12} className={`ctas ${secondaryCta ? 'twoCta' : 'oneCta'}`}>
        {form && (
          <HeroContextualCTA
            ctaPosition="emailcapture-hero"
            content={form}
            inlineMarketingForm={form}
            contentLeftAligned={false}
            fullWidth
          />
        )}
        {(primaryCta || secondaryCta) && (
          <div className="cta-container">
            {primaryCta && <HeroContextualCTA content={primaryCta} />}
            {secondaryCta && (
              <div className="secondary-cta">
                <ContentfulCallToAction {...secondaryCta} />
              </div>
            )}
          </div>
        )}
        {socialProof && (
          <div className="social-proof-container">
            <WebsiteImage {...socialProof} className="social-proof" />
          </div>
        )}
      </Col>
    </StyledHeading>
  );
};

export default Heading;
