import React from 'react';
import AnimatedHero from './AnimatedHero';
import RotatingBackgroundHero from './RotatingBackgroundHero';

const VerticalHero = (props) => {
  const { Variation } = props?.options || {};
  const isOneCtaVariation = Variation === 'One CTA';
  const isTwoCtaVariation = Variation === 'Two CTAs';
  const isEmailCtaVariation = Variation === 'Email';
  const isCtaVaration = isOneCtaVariation || isTwoCtaVariation || isEmailCtaVariation;

  if (isCtaVaration) return <AnimatedHero {...props} />;
  if (Variation === 'Rotating Background Images') return <RotatingBackgroundHero {...props} />;
  return null;
};

export default VerticalHero;
