import styled from 'styled-components';
import { Row } from 'components/Core/Grid';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { SharedBottomGradient, SharedTopGradient } from '../SharedComponents/SharedStyles';

const HeroContainer = styled(SectionLayout)`
  text-align: center;
  background: ${({ backgroundImage, backgroundColor }) =>
    (backgroundImage ? `url(${backgroundImage})` : 'var(--skyline-3)') ||
    (backgroundColor && `var(${backgroundColor})`) ||
    'var(--skyline-3)'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 575px) {
    background: transparent;
  }
  .section-layout--inner-container {
    position: relative;
    padding-top: 260px;
    padding-bottom: 375px;

    @media (max-width: 1199px) {
      padding-bottom: 305px;
      padding-top: 170px;
    }
    @media (max-width: 767px) {
      padding-bottom: 205px;
    }
    @media (max-width: 575px) {
      ${({ backgroundMobileImage }) =>
        backgroundMobileImage ? `background-image: url(${backgroundMobileImage})` : ''};
      padding-bottom: 150px;
      padding-top: 440px;
    }
  }

  h1 {
    padding: 0px 10px;
  }
  .hero-contextual-cta--origin {
    margin-top: 0px;
  }
  .crossfade-image {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      position: absolute !important;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
`;

const ContentContainer = styled(Row)`
  position: relative;
  flex-direction: column;
  z-index: 2;
`;

const TopGradient = styled.div`
  ${SharedTopGradient}
`;

const BottomGradient = styled.div`
  ${SharedBottomGradient}
  @media (max-width: 992px) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) -3.08%, rgba(0, 0, 0, 0) 19.7%);
  }
`;

const BottomBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 60%),
    linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 20%),
    linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%),
    linear-gradient(270deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 20%);
  z-index: 1;
  left: 0;
  bottom: 0;
`;

export { HeroContainer, ContentContainer, TopGradient, BottomGradient, BottomBackground };
