import React from 'react';
import { Row } from 'components/Core/Grid';
import { colorTypeHelper } from 'utils/colorUtility';
import { getEntry, getEntries } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import Heading from '../SharedComponents/Heading';
import FeaturedImages from '../SharedComponents/FeaturedImages';
import { HeroContainer, BottomGradient } from './styles';

const AnimatedHero = ({ entries }) => {
  const { backgroundImage, backgroundColor } = getEntry('section', entries)?.styles || {};
  const socialProof = getEntry('social-reviews', entries);
  const bgColor = colorTypeHelper[backgroundColor];

  return (
    <>
      <HeroContainer
        backgroundColor={bgColor}
        backgroundImage={backgroundImage?.image?.file?.url}
        backgroundMobileImage={backgroundImage?.imageMobile?.file?.url}
        socialProof={!!socialProof}
      >
        <Row justify="center">
          <Heading
            heading={getEntry('heading', entries)}
            form={getEntry('form', entries)}
            primaryCta={getEntry('primary-cta', entries)}
            secondaryCta={getEntry('secondary-cta', entries)}
            socialProof={socialProof}
          />
          <FeaturedImages
            primaryImage={getEntry('image', entries)}
            sideImages={getEntries('side-image', entries)}
          />
        </Row>
      </HeroContainer>
      <BottomGradient />
    </>
  );
};

export default AnimatedHero;
