import React from 'react';
import { colorTypeHelper } from 'utils/colorUtility';
import { getEntry, getEntries } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import Heading from '../SharedComponents/Heading';
import FeaturedImages from '../SharedComponents/FeaturedImages';
import CrossfadeImages from './CrossfadeImages';
import { HeroContainer, BottomGradient, ContentContainer, BottomBackground } from './styles';

const RotatingBackgroundHero = ({ entries }) => {
  const { backgroundImage, backgroundColor } = getEntry('section', entries)?.styles || {};
  const bgColor = colorTypeHelper[backgroundColor];

  return (
    <>
      <HeroContainer
        backgroundImage={backgroundImage?.image?.file?.url}
        backgroundMobileImage={backgroundImage?.imageMobile?.file?.url}
        backgroundColor={bgColor}
      >
        <CrossfadeImages
          className="max-sm"
          images={getEntries('rotating-image', entries)}
          duration={4000}
        />

        <ContentContainer justify="center">
          <Heading heading={getEntry('heading', entries)} form={getEntry('form', entries)} />
          <FeaturedImages
            primaryImage={getEntry('image', entries)}
            sideImages={getEntries('side-image', entries)}
            featuredColumnTop={[40, 140]}
            featuredColumnMobileTop={[0, 100]}
          />
        </ContentContainer>
        <BottomBackground className="max-sm" />
      </HeroContainer>
      <BottomGradient />
    </>
  );
};

export default RotatingBackgroundHero;
